import _is from "./is";
import _not from "./not";
import _keys from "./keys";
import _copy from "./copy";
var exports = {};
var is = _is,
    not = _not,
    keys = _keys,
    copy = _copy;

exports = function extend(to) {
  return function (from) {
    keys(from).filter(not(is.in(to))).map(copy(from, to));
    return to;
  };
};

export default exports;